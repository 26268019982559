@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "ZillaSlab";
  src: local("ZillaSlab"), url(./fonts/ZillaSlab-Regular.ttf) format("truetype");
}

* {
  font-family: "Roboto";
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "ZillaSlab";
  font-weight: bold;
}

b {
  font-family: inherit;
}

h1 {
  margin-top: 0;
  font-weight: 300;
}

input {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

a {
  text-decoration: none;
  color: var(--lightBlue);
}

:root {
  --darkBlue: rgb(0, 86, 97);
  --lightBlue: rgb(0, 171, 194);
  --orange: rgb(255, 96, 56);
  --green: rgb(4, 155, 78);
  --darkGreen: rgb(2, 78, 39);
  --lightGreen: rgb(54, 175, 113);
  --invalidYellow: rgb(255, 193, 7);
  --loadingAnimationBackgroundColor: rgba(0, 86, 97, 0.5);
  --textMargin: 3rem;
}

html {
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  min-height: 100%;
  background-color: rgb(245, 246, 247);
  padding: 0;
  margin: 0;
}

#root {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  :root {
    --textMargin: 1.7rem;
  }

  h1 {
    font-size: 1.6em;
  }
}
